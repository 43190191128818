import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

Modal.setAppElement('#root');

const CreateProductModal = ({ isOpen, onRequestClose, onCreateOrUpdateProduct, editingProduct  }) => {
  const [newProductData, setNewProductData] = useState({
    title: '',
    price: '',
    description: '',
  });

  useEffect(() => {
    // Actualizar el estado cuando el modal se abre y hay datos de edición
    if (isOpen && editingProduct) {
      setNewProductData({
        title: editingProduct.title,
        price: editingProduct.price,
        description: editingProduct.description,
      });
    }
  }, [isOpen, editingProduct]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProductData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCreateOrUpdate = () => {
    // Puedes manejar la lógica de creación o actualización aquí
    onCreateOrUpdateProduct({
      title: newProductData.title,
      price: newProductData.price,
      description: newProductData.description,
    });
  
    // Limpiar el formulario después de la acción
    setNewProductData({
      title: '',
      price: '',
      description: '',
    });
  
    // Cerrar el modal
    onRequestClose();
  };

  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Modal Simple"
    className="Modal fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-md p-6 max-w-md"
    overlayClassName="Overlay fixed inset-0 bg-black bg-opacity-50"
  >
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-lg font-medium">Crear Nuevo Producto</h2>
      <button
        onClick={onRequestClose}
        className="text-gray-500 hover:text-gray-700 focus:outline-none"
      >
        <FaTimes />
      </button>
    </div>
    <form>
      <label className="block">Title:</label>
      <input
        type="text"
        name="title"
        value={newProductData.title}
        onChange={handleInputChange}
        className="w-full border p-2 rounded-md focus:outline-none focus:border-blue-500"
      />

      <label className="block">Price:</label>
      <input
        type="text"
        name="price"
        value={newProductData.price}
        onChange={handleInputChange}
        className="w-full border p-2 rounded-md focus:outline-none focus:border-blue-500"
      />

      <label className="block">Description:</label>
      <input
        type="text"
        name="description"
        value={newProductData.description}
        onChange={handleInputChange}
        className="w-full border p-2 rounded-md focus:outline-none focus:border-blue-500"
      />

      <button
        type="button"
        onClick={handleCreateOrUpdate}
        className="bg-blue-500 py-2 px-4 rounded-lg text-white hover:bg-blue-400"
      >
        Crear/Actualizar Producto
      </button>
    </form>
  </Modal>
  );
};

export { CreateProductModal };
