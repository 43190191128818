import virtualCard from "../../../assets/landing-page/decoration/plan-virtual2.png";
import basicCard from "../../../assets/landing-page/decoration/plan-simple-modelo.png";
import customCard from "../../../assets/landing-page/decoration/plan-medio-modelo.png";
import fullDesignCard from "../../../assets/landing-page/decoration/plan-avanzado-modelo.png";

import comboFullDesignCard from "../../../assets/landing-page/decoration/propuesta/plan-fulldesign-modelo.png";
import comboNFCCard from "../../../assets/landing-page/decoration/propuesta/plan-nfc-modelo.png";

export const productCodes = {
    DIGITAL: 1,
    
}

export const defaultProducts = {
  [productCodes.DIGITAL]: {
    id: productCodes.DIGITAL,
    title: "Tarjeta Digital",
    image: virtualCard,
    description: "Disfruta una tarjeta digital",
    price: 50000,
    formattedPrice: (new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' })).format(50000)
  },
};

