import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import { Popover, PopoverTrigger } from "@radix-ui/react-popover";
import { useState, memo } from "react";
import { FaSort, FaTag, FaUserPlus,FaPen, FaPercentage, FaCrown, FaEnvelope, FaTrash, FaAt } from "react-icons/fa";
import { toast } from "react-toastify";
import { IMAGE_DOMAIN } from "../../../config/Constant";
import { getUserInitials } from "../../../utils/getUserInitials";
import { generateRandomKey } from "../../../utils/generateRandomKey";
import { addDurationToDate, formatDate } from "../../../utils/licenseDate";
import { CreateEmployeesModal } from "./CreateEmployeesModal";
import { ExtendLicensePopOver } from "./ExtendLicensePopOver";
import { UserRolTag } from "./UserRolTag";
import "../Components/adminComponentStyles.css";
import DiscountForm from './DiscountForm';
import {SimpleEditEmailModal} from '../Components/SimpleEditEmailModal';
import {SimpleDeleteModal} from '../Components/SimpleDeleteModal';

function licencePercentageCompleted(date1, date2) {
  const diff = date1.getTime() - date2.getTime();
  const daysTotal = diff / (1000 * 60 * 60 * 24);

  const today = new Date();
  const diffCompleted = date2.getTime() - today.getTime();
  const daysCompleted = Math.abs(diffCompleted / (1000 * 60 * 60 * 24));
  const percentageCompleted = Math.min(daysCompleted, daysTotal) / daysTotal;


  if (percentageCompleted < 0.1) {
    return 0;
  }
  return percentageCompleted;
}

// TODO: Remover clase innecesaria
export class User {
  licencia = {
    id: 0,
    custom_user_admin: 0,
    tipo_de_plan: "",
    fecha_fin: "",
    fecha_inicio: "",
    duracion: 0,
    status: 0,
    date_joined: "",
  };

  custom_user = {
    id: 0,
    email: "",
    username: "",
    rubro: "",
    is_editable: false,
    is_active: false,
    is_sponsor: false,
    is_booking: false,

  };

  profile = {
    id: 0,
    public_name: "",
    image: "",
  };
  constructor(object) {
    this.licencia = object.licencia;
    this.custom_user = object.custom_user;
    this.profile = object.profile;
  }
}

export function UserTableRow(props) {
  let { userData, setUser, setUserPersonified, setEditUserModal, index, onSelectUser } = props;
  let user = new User(userData);
  const startDate = new Date(user.licencia.fecha_inicio);
  const duration = user.licencia.duracion;
  const finalDate = addDurationToDate(startDate, duration);

  function onCompleteExtendLicense(licencia) {
    userData.licencia = licencia;
    setUser(userData);

    toast.success("Licencia modificada exitosamente", {
      position: "top-center",
      bodyClassName: "text-sm",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  function handlePersonifyUser() {
    setUserPersonified(user.custom_user);
  }

  function handleEditUserModal() {
    user.index = index; // Pq? Para acceder la fila a la que pertenece
    setEditUserModal(user);

    onSelectUser(user.custom_user.id);
  }

  const percentageCompleted = licencePercentageCompleted(finalDate, startDate);

  const [modalId, setModalId] = useState(generateRandomKey());

  function reloadFormState(open) {
    if (open) return;
    setModalId(Math.random().toString(36).substring(2, 10));
  }

  const [isDiscountFormOpen, setIsDiscountFormOpen] = useState(false);

  function handleDiscountFormOpen() {
    setIsDiscountFormOpen(true);
  }

  function handleDiscountFormClose() {
    setIsDiscountFormOpen(false);
  }

  const [editModal, setEditModal] = useState(false);

  function handleEditModalOpen() {
    setEditModal(true);
  }

  function handleEditModalClose() {
    setEditModal(false);
  }

  const [deleteModal, setDeleteModal] = useState(false);

  function handleDeleteModalOpen() {
    setDeleteModal(true);
  }

  function handleDeleteModalClose() {
    setDeleteModal(false);
  }

  return (
    <>
    <tr className="border-b border-stone-200 text-stone-900 hover:bg-stone-100">
      <td className="pl-1 pr-1 py-4 flex items-center whitespace ">
        <div className=" cursor-pointer group relative">
          <Avatar className=" mr-2 h-10 w-10 pointer-events-none select-none text-sm inline-flex justify-center items-center text-center rounded-full bg-stone-200">
            <AvatarImage
              className="rounded-full aspect-square object-cover "
              loading="lazy"
              src={`${IMAGE_DOMAIN}${user.profile.image}`}
            />
            <AvatarFallback>{getUserInitials(user.profile.public_name, user.custom_user.username)}</AvatarFallback>
          </Avatar>
        </div>
        
        <div className="flex flex-col">
          <a
            href={`https://www.soyyochile.com/u/#/${user.custom_user.username}`}
            target="_blank"
            rel="noreferrer"
            className=" inline-flex font-medium hover:underline"
          >
            {`${user.custom_user.username} (${user.custom_user.id})`}
          </a>
          <UserRolTag user={user} />
          <div className="text-xs text-stone-600">{user.custom_user.email}</div>
        </div>
      </td>

      <td className="px-2 py-4">
        <div className="capitalize">
          {user.licencia.tipo_de_plan} {user.custom_user.is_admin}{" "}
        </div>
        <p className="text-xs">Desde:</p>
        <div className="text-xs font-bold"> {formatDate(new Date(user.custom_user.date_joined))}</div>
      </td>
      <td className="px-2 py-4">

        <Popover>
          <PopoverTrigger>
            <div className="px-3 py-1 w-48 cursor-pointer isolate relative rounded-xl text-center whitespace-nowrap font-medium bg-green-500 text-white">
              <div>{`${formatDate(startDate)} - ${formatDate(finalDate)}`}</div>
              <div
                style={{
                  width: `${percentageCompleted * 100}%`,
                }}
                className={`${
                  percentageCompleted > 0.99 ? "bg-red-600" : "bg-green-600"
                } absolute inset-0 right-auto rounded-xl -z-10`}
              ></div>
            </div>
          </PopoverTrigger>
          <div className="mt-1 text-xs flex items-center">
            <p>Id Licencia:</p>
            <p className="ml-1 text-base font-semibold">{user.licencia.id}</p>
          </div>

          <ExtendLicensePopOver
            startDate={startDate}
            finalDate={finalDate}
            licenciaId={user.licencia.id}
            onComplete={onCompleteExtendLicense}
          />
        </Popover>
      </td>

      <td className=" px-2 py-4 font-medium whitespace-nowrap flex">
        <button onClick={handleEditUserModal} className="p-2 cursor-pointer hover:bg-violet-400 bg-violet-500 rounded-md text-white hover:underline relative group">
          <FaCrown className="text-sm"/> 
          <span className="select-none pointer-events-none opacity-0 text-xs bg-neutral-700 text-white py-2 px-2 rounded-md absolute z-10 right-0 transform translate-x-2/3 bottom-full transition-opacity duration-300 group-hover:opacity-100">
            Editar Roles
          </span>
        </button>

        <button onClick={handleEditModalOpen} className="p-2 ml-1 cursor-pointer hover:bg-blue-400 bg-blue-500 rounded-md text-white hover:underline relative group">
          <FaAt className="text-sm"/> 
          <span className="select-none pointer-events-none opacity-0 text-xs bg-neutral-700 text-white py-2 px-2 rounded-md absolute z-10 right-0 transform translate-x-2/3 bottom-full transition-opacity duration-300 group-hover:opacity-100">
            Editar Correo Electrónico
          </span>
        </button>
      

        <button 
          onClick={handlePersonifyUser} 
          className="p-2 ml-1 cursor-pointer hover:bg-emerald-400 bg-emerald-500 rounded-md text-white hover:underline relative group flex items-center"
        >
          <FaPen className="mr-1 text-sm"/> 
          <p className="text-xs">Perfil</p>
          <span className="select-none pointer-events-none opacity-0 text-xs bg-neutral-700 text-white py-2 px-2 rounded-md absolute z-10 right-0 transform translate-x-2/3 bottom-full transition-opacity duration-300 group-hover:opacity-100">
            Editar Perfil
          </span>
        </button>

        <Dialog key={modalId} onOpenChange={reloadFormState}>
          <DialogTrigger asChild>
            <button className="p-2 ml-1 cursor-pointer hover:bg-teal-400 bg-teal-500 rounded-md text-white hover:underline relative group">
              <FaUserPlus className="text-sm"/> 
              <span className="select-none pointer-events-none opacity-0 text-xs bg-neutral-700 text-white py-2 px-2 rounded-md absolute z-10 right-0 transform translate-x-2/3 bottom-full transition-opacity duration-300 group-hover:opacity-100">
                Crear Usuarios
              </span>
            </button>
          </DialogTrigger>
          <CreateEmployeesModal licencia={user.licencia} />
        </Dialog>

        {user.custom_user.is_sponsor && (  
          <>
            <button onClick={handleDiscountFormOpen} className="p-2 ml-1 cursor-pointer hover:bg-amber-400 bg-amber-500 rounded-md text-white hover:underline relative group">
              <FaPercentage/>
              <span className="select-none pointer-events-none opacity-0 text-xs bg-neutral-700 text-white py-2 px-2 rounded-md absolute z-10 right-0 transform translate-x-2/3 bottom-full transition-opacity duration-300 group-hover:opacity-100">
                  Crear Descuento
                </span>
            </button>
          </>
        )}
        <button onClick={handleDeleteModalOpen} className="p-2 ml-1 cursor-pointer hover:bg-red-400 bg-red-500 rounded-md text-white hover:underline relative group">
          <FaTrash className="text-sm"/> 
          <span className="select-none pointer-events-none opacity-0 text-xs bg-neutral-700 text-white py-2 px-2 rounded-md absolute z-10 right-0 transform translate-x-2/3 bottom-full transition-opacity duration-300 group-hover:opacity-100">
            Eliminar Cuenta
          </span>
        </button>
      </td>

    </tr>

    {isDiscountFormOpen && (
      <DiscountForm isOpen={isDiscountFormOpen} onClose={handleDiscountFormClose} userId={user.custom_user.id} username={user.custom_user.username} />
    )}
    {editModal && (
      <SimpleEditEmailModal isOpen={editModal} onClose={handleEditModalClose} userEmail={user.custom_user.email} userId={user.custom_user.id}/>
    )}
    {deleteModal && (
      <SimpleDeleteModal isOpen={deleteModal} onClose={handleDeleteModalClose} userId={user.custom_user.id} username={user.custom_user.username} customUser={user}/>
    )}

</>
  );
}

export function UsersTableHeader() {
  return (
    <thead className="border-b">
      <tr>
        <th scope="col" className="pl-2 pr-2 py-3">
          <div className="flex items-center font-medium">
            Usuario
            <FaSort className="ml-1 box-content p-1 cursor-pointer" />
          </div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium">Plan</div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium">Licencia</div>
        </th>
        <th scope="col" className="px-2 py-3">
          <div className="flex items-center font-medium">Acciones</div>
        </th>
      </tr>
    </thead>
  );
}
