/* import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDbkLm-AW1Ai6Qd8IoCG59_BtMPoO3NxfE",
  authDomain: "soyyo-auth.firebaseapp.com",
  projectId: "soyyo-auth",
  storageBucket: "__BUCKET__",
  messagingSenderId: "___sender_id__",
  appId: "__appid__",
  measurementId: "optional",
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); */


import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBNsUKCZ59ec93C2UCEKdpt3GzsrRqIfiA",
  authDomain: "soyyochile-auth.firebaseapp.com",
  projectId: "soyyochile-auth",
  storageBucket: "soyyochile-auth.appspot.com",
  messagingSenderId: "896360416795",
  appId: "1:896360416795:web:cd0b828aa194a2ac6b1abe",
  measurementId: "G-FRG9WV3K2H"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);