import React, { useEffect, useState } from 'react';
import { getClientContactList, updateRegister } from '../Api/ClientContact';
import { FaCheck, FaBell } from 'react-icons/fa';
import { ClientContactModal } from './ClientContactModal';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf'; // Importar la biblioteca jsPDF
import 'jspdf-autotable'; // Importar la biblioteca jsPDF-AutoTable
import { getAllEmployees } from '../../admin/Api/AdminServices';
import { IMAGE_DOMAIN } from '../../../config/Constant';


function ClientContactTableAdmin(props) {
  const { user, token } = props;
  const [contactList, setContactList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getAllEmployees(token, user);
        setEmployees(response.data);
      } catch (error) {
        console.error('Error al obtener la lista de empleados:', error);
      }
    };

    fetchEmployees();
  }, [token]);

  useEffect(() => {
    const fetchContactLists = async () => {
      try {
        // Obtener la lista de empleados
        const employees = await getAllEmployees(token, user);

        // Iterar sobre cada empleado
        for (const employee of employees.data) {
          // Obtener la lista de contactos del cliente para cada ID de empleado
          const data = await getClientContactList(employee.id, token);

          // Agregar la lista de contactos obtenida al estado
          const contactsWithData = data.map(contact => ({
            ...contact,
            employeeName: employee.username,
            employeeImage: employee.profile.image
          }));

          setContactList(prevContactList => prevContactList.concat(contactsWithData));
        }
      } catch (error) {
        console.error('Error al obtener los datos de contacto del cliente:', error);
      }
    };

    fetchContactLists();
  }, [token]);





  const handleOpenModal = (contact) => {
    setSelectedContact(contact);
    setModalOpen(true);

    // Si el estado del contacto es "no leído" (0), actualizarlo a "leído" (1)
    if (contact.status === 0) {
      updateContactStatus(contact.id);
    }
  };

  const handleCloseModal = () => {
    setSelectedContact(null);
    setModalOpen(false);
  };

  const updateContactStatus = async (contactId) => {
    try {
      const selectedContact = contactList.find(contact => contact.id === contactId);
      if (!selectedContact) {
        console.error('Contacto no encontrado');
        return;
      }

      const updatedData = {
        status: 1, // Nuevo valor de status
        customer_user_id: user, // Agregando el ID del usuario como customer_user_id
        name: selectedContact.name // Agregando el nombre del contacto seleccionado como name
      };

      await updateRegister(contactId, updatedData, token);

      // Actualizar el estado del contacto localmente en la lista de contactos
      setContactList(prevContactList =>
        prevContactList.map((c) =>
          c.id === contactId ? { ...c, status: 1 } : c
        )
      );
    } catch (error) {
      console.error('Error al actualizar el estado del contacto:', error);
    }
  };

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const options = {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return date.toLocaleDateString('es-ES', options);
  };

  // Función para exportar los datos de contacto a un archivo Excel
  const exportToExcel = () => {
    const data = contactList.map(contact => ({
      'Usuario': contact.employeeName,
      'Nombre': contact.name,
      'País': contact.country_code,
      'Teléfono': contact.phone,
      'Email': contact.email,
      'Comentario': contact.comment,
      'Fecha de Creación': formatCreatedAt(contact.created_at),
      'Estado': contact.status === 0 ? 'No Leído' : 'Leído'
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contactos');
    XLSX.writeFile(workbook, 'contactos.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['usuario', 'Nombre', 'País', 'Teléfono', 'Email', 'Comentario', 'Fecha de Creación', 'Estado']],
      body: contactList.map(contact => [
        contact.employeeName,
        contact.name,
        contact.country_code,
        contact.phone,
        contact.email,
        contact.comment,
        formatCreatedAt(contact.created_at),
        contact.status === 0 ? 'No Leído' : 'Leído'
      ]),
      startY: 20,
      styles: {
        fontSize: 10,
        cellPadding: 2
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 15 },
        4: { cellWidth: 30 },
        5: { cellWidth: 20 }
      }
    });
    doc.save('contactos.pdf');
  };

  const handleChangeEmployeeFilter = (event) => {
    setEmployeeFilter(event.target.value);
  };

  const filteredContactList = employeeFilter
  ? contactList.filter(contact => contact.employeeName === employeeFilter)
  : contactList;

let combinedList = [...filteredContactList]; // Copia la lista filtrada inicialmente

if (employeeFilter === '') {
  combinedList = contactList; // Usa la lista completa si no hay filtro
}

// Ordena la lista combinada por fecha de creación
combinedList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <div>
      <div className='flex justify-between mb-4'>
        <h2 className="font-medium text-lg text-gray-800">Registro de Notificaciones</h2>
        <div className='flex items-center'>
          <select
            className="bg-neutral-800 text-white py-1 px-2 rounded-md"
            value={employeeFilter}
            onChange={handleChangeEmployeeFilter}
          >
            <option value="">Todos los usuarios</option>
            {employees.map(employee => (
              <option key={employee.id} value={employee.username}>{employee.username}</option>
            ))}
          </select>
          <button onClick={exportToExcel} className="bg-green-500 text-white py-1 px-2 text-sm font-semibold rounded-md ml-2">Exportar a Excel</button>
          <button onClick={exportToPDF} className="bg-blue-500 text-white py-1 px-2 text-sm font-semibold rounded-md ml-2">Exportar a PDF</button>
        </div>
      </div>

      {filteredContactList.map(contact => (
        <div
          key={contact.id}
          className="rounded-lg border border-neutral-300 shadow-md bg-neutral-100 p-1 px-2 md:p-4 flex flex-col md:flex-row md:items-center justify-between mb-4 cursor-pointer hover:bg-neutral-200"
          onClick={() => handleOpenModal(contact)}
        >
          <div className="flex items-center">
            <img
              src={`${IMAGE_DOMAIN}${contact.employeeImage}`}
              className="rounded-full h-12 w-12 mr-4"
              alt={contact.employeeName}
              title={contact.employeeName}
            />
            <div>
              <p className="text-sm font-medium mb-1">
                <strong>{contact.name}</strong> Quiere entrar en contacto
              </p>
              <p className="text-sm text-gray-600">
                {formatCreatedAt(contact.created_at)}
              </p>
            </div>
          </div>
          <div className="flex items-center mt-4 md:mt-0">
            <div className="flex items-center mr-4">
            <span className='text-neutral-800 text-xs font-medium px-2 whitespace-nowrap'>
                {contact.status === 0 ? 'No Leído' : 'Leído'}
              </span>
              {contact.status === 0 ? (
                <div className="flex items-center justify-center bg-blue-500 text-white rounded-full w-6 h-6 mr-2">
                  <FaBell className="text-white"></FaBell>
                </div>
              ) : (
                <div className="flex items-center justify-center bg-green-500 text-white rounded-full w-6 h-6 mr-2">
                  <FaCheck className="text-white"></FaCheck>
                </div>
              )}
              
            </div>
          </div>
        </div>
      ))}

      <ClientContactModal isOpen={modalOpen} onClose={handleCloseModal} contact={selectedContact} />
    </div>
  );
}

export { ClientContactTableAdmin };
