import React from "react";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RouterConfig from "./config/RouterConfig";

function App() {
  return (
    <div>
      <ToastContainer />
      <HashRouter>
        <>
          <RouterConfig />
        </>
      </HashRouter>
    </div>
  );
}

export default App;