import CardContainer from "../../components/CardContainer";
import React, { useState, useEffect } from 'react';
import { getAllProducts, createNewProduct, updateProductById, deleteProductById } from './Api/products';
import { useSelector } from 'react-redux';
import {CreateProductModal} from './Components/CreateProductModal';
import {ConfirmationProductModal} from './Components/ConfirmationProductModal';

import { FaPencilAlt, FaTrash } from 'react-icons/fa';


import virtualCard from "../../assets/landing-page/decoration/plan-virtual2.png";
import basicCard from "../../assets/landing-page/decoration/plan-simple-modelo.png";
import customCard from "../../assets/landing-page/decoration/plan-medio-capacitar.png";
import fullDesignCard from "../../assets/landing-page/decoration/plan-avanzado-gamefic.png";
import defaultImage from "../../assets/landing-page/decoration/plan-avanzado-modelo - copia.png";




export function ProductsTable() {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const [products, setProducts] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [newProductData, setNewProductData] = useState({
    title: '',
    price: '',
    description: '',
  });

  const fetchProducts = async () => {
    try {
      const response = await getAllProducts(token);
      setProducts(response.data);
    } catch (error) {
      console.error('Error al obtener productos', error);
    }
  };

  const getProductImage = (productId) => {
    switch (productId) {
      case 1:
        return virtualCard; // Ruta de la imagen para el producto con ID 1
      case 2:
        return basicCard; // Ruta de la imagen para el producto con ID 2
      case 3:
        return customCard; // Ruta de la imagen para el producto con ID 3
      case 4:
        return fullDesignCard; // Ruta de la imagen para el producto con ID 4
      // Agrega más casos según sea necesario
      default:
        return defaultImage; // Ruta de la imagen predeterminada o una imagen por defecto
    }
  };

  const handleCreateOrUpdateProduct = async (productData) => {
    try {
      if (editingProduct) {
        await updateProductById(token, editingProduct.id, productData);
      } else {
        await createNewProduct(productData, token);
      }
      fetchProducts();
      setEditingProduct(null);
    } catch (error) {
      console.error('Error al crear/actualizar producto', error);
    }
  };

  const handleEditClick = (product) => {
    setEditingProduct(product);
    setIsCreateModalOpen(true);
  
    // Llenar los campos del formulario con los datos del producto seleccionado
    setNewProductData((prevData) => ({
      ...prevData,
      title: product.title,
      price: product.price,
      description: product.description,
    }));
  
  };

  const handleConfirmDelete = async () => {
    try {
      if (productToDelete) {
        // Lógica para eliminar el producto
        await deleteProductById(token, productToDelete.id);
        fetchProducts(); // Actualizar la lista de productos después de la eliminación
      }
    } catch (error) {
      console.error('Error al eliminar el producto', error);
    } finally {
      setProductToDelete(null); // Limpiar el producto a eliminar
      setIsConfirmModalOpen(false); // Cerrar el modal de confirmación
    }
  };

  const handleCancelDelete = () => {
    setProductToDelete(null); // Limpiar el producto a eliminar
    setIsConfirmModalOpen(false); // Cerrar el modal de confirmación
  };

  const handleDeleteProduct = (product) => {
    setProductToDelete(product); // Establecer el producto a eliminar
    setIsConfirmModalOpen(true); // Abrir el modal de confirmación
  };


  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="pt-8 w-full font-montserrat">
      <div className="px-8 flex justify-between items-center">
        <h2 className="font-medium text-xl truncate">
          Productos
        </h2>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="bg-emerald-500 py-2 px-4 rounded-lg text-white text-sm font-semibold hover:bg-emerald-400"
        >
          Crear Producto
        </button>
      </div>

      <div className="mt-4 px-6 gap-8 grid grid-cols-1">
       
<CardContainer className="px-6 py-4 shadow-md">
  <h2 className="font-medium text-lg mb-4 text-gray-800">Nuestros Productos</h2>
  <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-3">
    {products.map((product) => (
      <div
        key={product.id}
        className="relative p-2 cursor-pointer bg-white border-2 border-neutral-300 shadow-lg transition duration-300 ease-in-out"
        
      >

        <div className="relative mb-2">
          <p className="text-amber-500 border-2 border-amber-500 bg-white  font-semibold absolute bottom-0 right-0 p-1">
            $ {product.price}
          </p>
          <p className="text-xs text-neutral-600 font-semibold">Producto Nº {product.id}</p>
          <img
            src={getProductImage(product.id)}
            alt={product.title}
            className="w-full h-fit object-cover rounded-md"
          />
        </div>
        <h3 className="font-semibold mr-2">
           {product.title}
        </h3>
        <p className="text-gray-700 text-sm">{product.description}</p>

        
        <div className="flex flex-row items-center mt-2 space-x-2 text-sm">
          <button
            className="flex-1 bg-blue-500 py-1 px-2 text-white hover:bg-blue-400 flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(product);
            }}
          >
            <FaPencilAlt className="mr-2" />
            Editar
          </button>
          <button
            className="flex-1 bg-red-500 py-1 px-2 text-white hover:bg-red-400 flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteProduct(product);
            }}
          >
            <FaTrash className="mr-2" />
            Eliminar
          </button>
        </div>
      </div>
    ))}
  </div>
</CardContainer>

        
      </div>


      <CreateProductModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        token={token}
        onCreateOrUpdateProduct={handleCreateOrUpdateProduct}
        editingProduct={editingProduct}
      />

      <ConfirmationProductModal
        isOpen={isConfirmModalOpen}
        onRequestClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message="¿Estás seguro de que deseas eliminar este producto?"
      />
    </div>
  );
}
