export function PaymentFormInputSelect(props) {
  const { label, register, options, defaultValue   } = props;

  return (
    <div className="flex flex-col">
      <label className="font-medium"> {label} </label>
      <select
      className="mt-1 px-4 py-2 text-sm rounded-md border outline outline-transparent transition-all duration-300 focus:border-transparent focus:outline-2 bg-transparent border-stone-300 focus:outline-sky-500"
      {...register}
      defaultValue={defaultValue}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option }
          </option>
        ))}
      </select>
    </div>
  );
}
