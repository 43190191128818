import { MdVerified } from "react-icons/md";
import nfc from '../../../assets/landing-page/decoration/nfc.png';

export function ProductsCard(props) {
  const { image, hoverImage, hoverText, title, description, buttonText, link } =
    props;

  const { durationTag } = props;

  const { onClickImageOrButton } = props;

  function handleClickImageOrButton(event) {
    if (onClickImageOrButton) {
      onClickImageOrButton();
      return;
    }
  }

  return (
    <div className="relative h-full flex flex-col items-center  bg-stone-200 border border-stone-200 p-2 rounded-lg shadow-lg mx-1">
        <button
      onClick={handleClickImageOrButton}
      className="group relative cursor-pointer"
      style={{ position: 'relative' }} // Añade esta línea para establecer la posición relativa
    >
      {/* Añadir la pequeña imagen superpuesta */}
      {title.toLowerCase() === 'tarjeta digital' && (
      <img
        src={nfc}
        alt="Small Icon"
        className="absolute top-4 left-1 w-12 h-12 z-8 group-hover:block hidden"
        style={{ position: 'absolute' }} // Añade esta línea para establecer la posición absoluta
      />
      )}
      <img
        src={image}
        aria-label={`comprar producto ${title}`}
        alt={title}
        className="h-36 rounded-md bg-cover group-hover:hidden relative"
      />
      <img
        src={hoverImage ?? image}
        alt={title}
        className="h-36 bg-cover hidden group-hover:block relative"
      />

      <div className="px-2 py-1 opacity-0 right-2 bottom-2 font-medium text-sm absolute transition-all group-hover:block group-active:scale-110 group-hover:opacity-100 bg-stone-950 text-stone-50 rounded-md">
        {hoverText}
      </div>
    </button>

      <h3 className="mt-4 font-semibold"> {title} </h3>
      <p className="text-center"> {description} </p>

      <div className="h-full"></div>
      <a
        onClick={handleClickImageOrButton}
        href={link}
        className="mt-4 px-4 py-1 rounded-lg bg-stone-950 text-stone-50 cursor-pointer"
      >
        {buttonText}
      </a>

      {durationTag ? (
        <div className="left-0 top-0 absolute z-9">
          <div className="px-1 py-1  rounded-md text-base flex items-center font-semibold bg-amber-500 text-white">
            <MdVerified />
            <span className="ml-1 text-white"> 1 año </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
