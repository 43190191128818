import React from 'react';
import virtualCard from "../../../assets/landing-page/decoration/plan-virtual2 - copia.png";
import { useNavigate } from 'react-router-dom';
import { ROUTER_PAYMENT } from "../../../config/Constant";

const ProductSection = () => {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        // Agrega lógica para el botón de compra aquí, si es necesario
    
        // Navegar a la ruta de Mercado Pago
        navigate(`${ROUTER_PAYMENT}?id=1`);
      };
  return (
    <div className="bg-stone-300  shadow-lg rounded w-5/6 h-full flex justify-center items-center">
        <div className="flex justify-between mb-6">
            
            <div className='w-1/2 object-cover rounded mr-1 md:mr-12'>
                <img
                className="w-60 h-60"
                src={virtualCard}
                alt="Tarjeta Digital"
                style={{ objectPosition: 'right' }}
                />
            </div>
            <div className="w-1/2 flex flex-col items-center mt-4">
                <div className="flex flex-col items-start justify-start text-start ">
                    <h2 className="text-3xl font-semibold text-neutral-800 mb-2 mt-2 md:mt-10">Tarjeta Digital</h2>
                    <p className="text-gray-600 mb-2 text-sm">Conéctate con nuestra tecnología.</p>
                    <div className="mb-2">
                        <p className="text-2xl font-semibold text-black-600 mb-2">$ 50.000,00</p>
                    </div>
                    <div>
                        <button
                        className="bg-neutral-950 text-white font-medium py-2 text-lg w-full p-4 rounded-md hover:bg-neutral-900"
                        onClick={handleButtonClick}
                        >
                        Comprarlo ya!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export { ProductSection };
