import React, { useEffect, useState } from "react";
import UserProfileLog from "./UserProfileLog";
import { getAllProfilesForUser } from "../../Api/analytics";

function AnalyticsModal({ isOpen, onClose, title, userProfileId, selectedLink, IMAGE_DOMAIN }) {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && userProfileId) {
      setLoading(true); // Activar el estado de carga al comenzar la solicitud
      getAllProfilesForUser(userProfileId)
        .then((profiles) => {
          const transformedProfiles = profiles.map((profile) => ({
            ...profile,
            timestamp: new Date(profile.timestamp).toLocaleString(),
          }));
          setProfiles(transformedProfiles);
        })
        .catch((error) => {
          console.error("Error al obtener perfiles:", error);
        })
        .finally(() => {
          setLoading(false); // Desactivar el estado de carga una vez que se complete la solicitud
        });
    }
  }, [isOpen, userProfileId]);

  function addHttp(url) {
    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
      url = "http://" + url;
    }
    return url;
  }

  function isPhoneNumber(url) {
    return url?.startsWith("tel:");
  }

  // Obtener los datos del objeto adecuado dentro de custom_social_list
  const socialData = selectedLink?.user?.statistics?.custom_social_list?.find(
    (item) => item.title === selectedLink.title
  );

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? "block" : "hidden"}`}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              {title}
            </h3>
            {loading ? ( // Mostrar mensaje de carga mientras se obtienen los perfiles
              <p className="text-gray-500">Cargando...</p>
            ) : (
              profiles.length > 0 ? ( // Verificar si hay perfiles disponibles
                <UserProfileLog profiles={profiles} />
              ) : ( // Mostrar mensaje si no hay perfiles disponibles
                <p className="text-gray-500">No hay perfiles disponibles.</p>
              )
            )}
          </div>

          <div className="">
            {selectedLink && socialData && (
              <>
                <div className="flex items-center justify-center">
                  {/* Primer conjunto de elementos */}
                  <div className="mr-8">
                    <img
                      className="w-30 h-30 object-cover rounded-lg"
                      src={`${IMAGE_DOMAIN}${selectedLink.image}`}
                      alt="Imagen del enlace"
                    />
                    <p className="font-medium text-sm">Visitado: {selectedLink.counter}</p>
                  </div>

                  {/* Segundo conjunto de elementos */}
                  <div>
                    <div className="mb-2">
                      <p className="text-sm font-semibold">Fecha Creado:</p>
                      <p className="text-sm">{new Date(socialData.created_at).toLocaleString()}</p>
                    </div>
                    <div className="mb-2">
                      <p className="text-sm font-semibold">Última Vez:</p>
                      <p className="text-sm">{new Date(socialData.update_at).toLocaleString()}</p>
                    </div>
                    <div className="mb-2">
                      <p className="text-sm font-semibold">Url:</p>
                      {isPhoneNumber(socialData.url) ? (
                        <button
                          className="text-sm text-blue-600 hover:underline focus:outline-none cursor-pointer"
                          onClick={() => window.open(`tel:${socialData.url}`, '_blank')}
                        >
                          Llamar
                        </button>
                      ) : (
                        <div
                          className="text-sm text-blue-600 hover:underline focus:outline-none cursor-pointer"
                          onClick={() => window.open(addHttp(socialData.url), '_blank')}
                        >
                          Visitar
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex items-center mt-4 justify-center">
                  <div className="mr-8">
                    <p className="text-sm font-semibold">Título:</p>
                    <p className="text-sm">{selectedLink.title}</p>
                  </div>

                  <div className="mr-8">
                    <p className="text-sm font-semibold">Tipo:</p>
                    <p className="text-sm">{socialData.type}</p>
                  </div>

                  <div>
                    <p className="text-sm font-semibold">Usuario:</p>
                    <p className="text-sm">{selectedLink.user.username}</p>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsModal;
