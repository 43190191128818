import axios from 'axios';
import { DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant";

// Crear un nuevo descuento
export const createNewProduct = async (data, token) => {
  try {
    const response = await axios.post(`${DOMAIN}pay/productos/`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Obtener información de un producto por ID
export const getProductById = (token, productId) => {
  return axios.get(`${DOMAIN}pay/productos/${productId}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};

// Actualizar un producto por ID
export const updateProductById = (token, productId, updatedData) => {
  return axios.put(`${DOMAIN}pay/productos/${productId}/`, updatedData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};

// Obtener todos los productos
export const getAllProducts = (token) => {
  return axios.get(`${DOMAIN}pay/productos/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};

// Eliminar un producto por ID
export const deleteProductById = (token, productId) => {
  return axios.delete(`${DOMAIN}pay/productos/${productId}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};
