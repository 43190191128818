import React, { useState, useEffect } from 'react';
import Reporte from './Report';
import Filters from './Filter';
import { useAllTransactions } from '../Api/Transactions';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

const Modal = ({ isOpen, closeModal, selectedTransaction }) => {
  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" onClick={closeModal}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-xl">
          <div className="bg-neutral-800 px-4 py-2 flex justify-between items-center">
            <h2 className="text-white text-sm font-semibold">Detalles de Venta</h2>
            <button className="text-white font-semibold bg-red-500 rounded-md px-2 py-1 text-sm" onClick={closeModal}>Cerrar</button>
          </div>
          <Reporte transaction={selectedTransaction} />
        </div>
      </div>
    </div>
  );
};

const SalesTable = ({ salesData, token }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [statusFilter, setStatusFilter] = useState('2');
  const [yearFilter, setYearFilter] = useState(null);
  const [monthFilter, setMonthFilter] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const { transactions, error } = useAllTransactions(token, statusFilter, yearFilter); 

  useEffect(() => {
    const filtered = transactions.filter(transaction => {
      if (yearFilter && monthFilter) {
        const transactionYear = new Date(transaction.date).getFullYear();
        const transactionMonth = new Date(transaction.date).getMonth() + 1; 
        return transactionYear.toString() === yearFilter && transactionMonth.toString() === monthFilter;
      } else if (yearFilter) {
        const transactionYear = new Date(transaction.date).getFullYear();
        return transactionYear.toString() === yearFilter;
      }
      return true; 
    });
    setFilteredTransactions(filtered);
  }, [transactions, yearFilter, monthFilter]);

  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredTransactions); // Usar los datos filtrados
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions'); 
    XLSX.writeFile(workbook, 'transactions.xlsx'); 
  };

  useEffect(() => {
  }, [statusFilter]);

  const handleFilterChange = (status, year, month) => {
    setStatusFilter(status);
    setYearFilter(year);
    setMonthFilter(month);

  };

  const handleCopyUrlClick = (idTransaccion) => {
    const url = `https://pay.scrum-technology.com/api/v2pro/pagow.php?m=I&it=${idTransaccion}`;
    navigator.clipboard.writeText(url)
      .then(() => {
        toast("Enlace copiado al portapapeles: " );
      })
      .catch((error) => {
        console.error("Error al copiar el enlace al portapapeles: ", error);
      });
  };

  return (
    <>
      <div className="flex justify-between">
      <h2 className="font-medium"> Registro de Ventas </h2>
        <button className='bg-green-500 m-1 px-2 py-1 text-white font-medium hover:bg-green-400 rounded-md' onClick={exportToExcel}>Exportar a Excel</button>
      </div>
      <Filters onFilterChange={handleFilterChange}/>
      <div className="flex justify-center">
        <div className=" overflow-x-auto">
        <Modal isOpen={isModalOpen} closeModal={closeModal} selectedTransaction={selectedTransaction} />
          <table className="border border-neutral-200 w-full">
            <thead className="bg-neutral-800 text-xs text-white font-medium ">
              <tr>
                <th className="px-6 py-3 text-left">ID</th>
                <th className="px-6 py-3 text-left">Cliente</th>
                <th className="px-6 py-3 text-left">Fecha</th>
                <th className="px-6 py-3 text-left">Responsable</th>
                <th className="px-6 py-3 text-left">Monto</th>
                <th className="px-6 py-3 text-left">Estado</th>
                <th className="px-6 py-3 text-left">Acciones</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-neutral-200" >
              {transactions.filter(transaction => {
                 if (yearFilter && monthFilter) {
                  const transactionYear = new Date(transaction.date).getFullYear();
                  const transactionMonth = new Date(transaction.date).getMonth() + 1; // Los meses van de 0 a 11
                  return transactionYear.toString() === yearFilter && transactionMonth.toString() === monthFilter;
                } else if (yearFilter) {
                  const transactionYear = new Date(transaction.date).getFullYear();
                  return transactionYear.toString() === yearFilter;
                }
                return true; 
              }).map(transaction => (
                <tr key={transaction.id} >
                <td className="px-1 py-2 text-xs font-semibold text-center">{transaction.id} </td>
                <td className="px-1 py-2">
                  {transaction.customer_user_email}
                  <br />
                  {transaction.customer_user_phone_number}
                </td>
                <td className="px-1 py-2">{new Date(transaction.date).toLocaleString('es-ES', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}</td>
                <td className="px-1 py-2 whitespace-nowrap">{transaction.vendedor}</td>
                <td className="px-1 py-2 whitespace-nowrap">{transaction.monto} {transaction.moneda}</td>
                <td className="px-1 py-2 whitespace-nowrap text-xs text-center">
                  {transaction.status === 2 ? (
                    <div className="bg-green-500 text-white font-bold p-2 rounded">
                      Pagado
                    </div>
                  ) : (
                    <div className="bg-red-500 text-white font-bold p-2 rounded">
                      No Pagado
                    </div>
                  )}
                </td>
                <td className="px-1 py-2 whitespace-nowrap font-semibold">
                  <div className="flex text-xs ">
                    <div className='bg-blue-500 m-1 p-2 text-white hover:bg-blue-400 rounded-md' onClick={() => openModal(transaction)}>ver</div>
                    <div className='bg-purple-500 m-1 p-2 text-white font-medium hover:bg-purple-400 rounded-md' onClick={() => handleCopyUrlClick(transaction.id_transaccion)}>url</div>
                  </div>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SalesTable;
