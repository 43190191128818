import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@radix-ui/react-dialog";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose, AiOutlineUserAdd } from "react-icons/ai";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingButton from "../../../components/LoadingButton";
import { createUser } from "../Api/superUserService";
import { REGEX_USERNAME } from "../../../config/RegexConstants";

const PlanCost = {
  BASIC: "basic",
  CUSTOM: "custom",
  FULL_DESIGN: "full-design",
};

function planTypeCost(plan) {
  switch (plan) {
    case PlanCost.BASIC:
      return 100;
    case PlanCost.CUSTOM:
      return 200;
    case PlanCost.FULL_DESIGN:
      return 350;
    default:
      return undefined;
  }
}

export function CreateUserModal(props) {
  const token = useSelector((state) => state.login.token);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      fecha_inicio: new Date().toISOString().substring(0, 10),
    },
    mode: "onChange",
  });

  async function handleCreateUser(data) {
    setIsLoading(true);
    createUser(data, token)
      .then((response) => {
        toast.success("Usuario creado exitosamente", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        if (closeRef) {
          closeRef.current.click();
        }
      })
      .catch((error) => {
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [isLoading, setIsLoading] = useState(false);
  const closeRef = useRef(null);

  return (
    <DialogPortal>
      <DialogOverlay className="fixed z-40 bg-stone-950/10 inset-0" />
      <DialogContent className="h-fit sm:max-w-screen-sm max-w-[640px] w-full z-50 right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 rounded-md fixed border border-stone-300 bg-white shadow-lg">
        <div className="px-7 py-6 h-full w-full font-montserrat">
          <div className="flex items-center">
            <AiOutlineUserAdd size={24} />
            <p className="ml-2 font-medium"> Agregar usuario </p>
          </div>
          <form
            onSubmit={handleSubmit(handleCreateUser)}
            className="mt-4 gap-4 text-sm flex flex-col"
          >
            <div>
              <label className="text-stone-800">Nombre de usuario</label>
              <input
                type="text"
                {...register("username", {
                  required: "Required",
                  pattern: {
                    message: "error",
                    value: new RegExp(REGEX_USERNAME),
                  },
                })}
                className={`mt-1 px-4 py-2 border outline-none w-full ${
                  errors.username ? "border-red-400" : "focus:border-stone-700"
                }`}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 sm:col-span-1">
                <label className="text-stone-800">Tipo de usuario</label>
                <select
                  {...register("tipo_usuario")}
                  className="mt-1 px-4 py-2 border bg-transparent outline-none focus:border-stone-700 w-full"
                >
                  <option value="Empresa"> Empresa </option>
                  <option value="Independiente"> Independiente </option>
                </select>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="text-stone-800">Tipo de plan</label>
                <select
                  {...register("tipo_de_plan", {
                    onChange: (event) => {
                      const plan = event.target.value;
                      let cost = planTypeCost(plan);
                      if (cost) {
                        setValue("cobro", cost);
                        clearErrors("cobro");
                      }
                    },
                  })}
                  className="mt-1 px-4 py-2 border bg-transparent outline-none focus:border-stone-700 w-full"
                >
                  <option value={PlanCost.BASIC}> Basic </option>
                  <option value={PlanCost.CUSTOM}> Custom </option>
                  <option value={PlanCost.FULL_DESIGN}> Full Design </option>
                </select>
              </div>

              <div className="col-span-2 sm:col-span-1">
                <label className="text-stone-800">Inicio de licencia</label>
                <input
                  className="mt-1 px-4 py-2 border outline-none focus:border-stone-700 w-full"
                  type="date"
                  {...register("fecha_inicio")}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="text-stone-800">
                  Duración de licencia (días)
                </label>
                <input
                  className={`mt-1 px-4 py-2 border outline-none w-full ${
                    errors.duracion
                      ? "border-red-400"
                      : "focus:border-stone-700"
                  }`}
                  type="text"
                  {...register("duracion", {
                    valueAsNumber: true,
                    validate: (value) => {
                      return value > 0;
                    },
                  })}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="text-stone-800">
                  Costo de licencia ($.)
                </label>
                <input
                  className={`mt-1 px-4 py-2 border outline-none w-full ${
                    errors.cobro ? "border-red-400" : "focus:border-stone-700"
                  }`}
                  type="text"
                  {...register("cobro", {
                    value: planTypeCost(PlanCost.BASIC),
                    valueAsNumber: true,
                    validate: (value) => value > 0,
                  })}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <DialogClose asChild>
                <button
                  ref={closeRef}
                  type="button"
                  className="py-2 col-span-2 sm:col-span-1 rounded-md text-base font-medium bg-stone-200 text-stone-800"
                >
                  Cancelar
                </button>
              </DialogClose>

              <LoadingButton
                className="py-2 col-span-2 sm:col-span-1 rounded-md text-base font-medium bg-emerald-600 text-white"
                isLoading={isLoading}
                color="text-transparent"
              >
                Crear usuario
              </LoadingButton>
            </div>
          </form>
        </div>
        <DialogClose asChild>
          <button type="button" className="top-5 right-5 absolute">
            <AiOutlineClose
              className="p-[6px] rounded-full box-content hover:bg-stone-100"
              size={20}
            />
          </button>
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  );
}
